import axios from 'axios';

axios.defaults.headers.post['Content-Type'] = 'application/json';
/**
 * axios 请求拦截
 */
axios.interceptors.request.use(
  config => {
    // 统一在http请求的header都加上token
    // const token = localStorage.getItem('token');
    // token && (config.headers.authorization = "Bearer " + token);
    config.headers.Authorization = "dc0eb70ccb5df4c62c86c40c748365be"
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject(error)
  }
)


/**
 * axios 异常处理 
 */
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      // console.log('code', response.data.code);
      switch (response.data.code) {
        case 0:
          return Promise.resolve(response);
        case 401:
          console.log("401 未登录");
          break;
        case 403:
          console.log("403 登录过期");
          break;
        case 404:
          console.log("404 页面不存在");
          break;
        default:
          // console.log("未知错误", response.data.msg);
          break;
      }
      return Promise.resolve(response);

    } else {
      return Promise.reject(response);
    }
  },
  error => {
    // console.log('error', error.response)
    if (error.response) {
      switch (error.response.code) {
        case 401:
          console.log("401 未登录");
          break;
        case 403:
          console.log("403 登录过期");
          break;
        case 404:
          console.log("404 页面不存在");
          break;
        default:
          console.log('');
          break;
      }
    }
  }
)


/**
 * get 方法
 * @param {String} url 
 * @param {JSON} params 
 */

export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, params).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}
/**
 * post方法
 * @param {String} url [请求地址]
 * @param {JSON} params [请求参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}